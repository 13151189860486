<template>
  <section>
    <v-container>
      <div id="return" class="my-6">

      </div>
      <div id="privacy" class="my-6">
        <v-card class="rounded-lg" flat>
          <v-card-title>
            Online Privacy Overview
          </v-card-title>
          <v-card-text>
            At <strong>Salt & Sea Scuba</strong>, our website employs cookies to elevate your browsing experience, ensuring seamless navigation. These small text files on your device store preferences and interactions. We utilize necessary and third-party cookies, vital for site functionality and insightful analysis of user engagement. While we value responsible data collection, including IP address, device details, and more, rest assured, payment data is securely managed by <em>Stripe</em>. For any inquiries, our customer support guarantees transparency during business hours.
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'policy',
  mounted () {
    this.$vuetify.goTo(`#${this.$route.params.scrollTo}`, {
      duration: 500,
      offset: 0
    })
  },
  created () {
    this.doSetDocumentTitle('Policies', true)
  }
}
</script>

<style scoped>

</style>
